import {registerInitializer} from "../shared/utils";

export const initializeHeader = () => {
  window.onscroll = function() {
    if ($(document).scrollTop() > 30) {
      $('.wrapper.sticky').addClass('wrapper-small');

      if ($('#intro').length > 0) {
        $('#intro').addClass('intro-small');
      }
    } else {
      $('.wrapper.sticky').removeClass('wrapper-small');

      if ($('#intro').length > 0) {
        $('#intro').removeClass('intro-small');
      }
    }

    return;
  };
};

registerInitializer(initializeHeader);
