import {registerInitializer} from "../shared/utils";

import "malihu-custom-scrollbar-plugin/jquery.mCustomScrollbar.js"
import 'malihu-custom-scrollbar-plugin/jquery.mCustomScrollbar.css';

export const initializeNavigationSlider = () => {
  $('#sidebar').mCustomScrollbar({
    theme: 'minimal'
  });

  $('.sidebar-content .active-element').on('click', function(event) {
    $('#sidebar').removeClass('active');
    $('.overlay').removeClass('active');
    $('footer').show();
    $('.content').removeClass('visibility-hidden');
    $('.menu-toggle').removeClass('menu-active');
  });

  $('#sidebarCollapse').on('click', function(event) {
    if($('#sidebar').hasClass('active')) {
      $('#sidebar').removeClass('active');
      $('.overlay').removeClass('active');
      $('footer').show();
      $('.content').removeClass('visibility-hidden');
      $('.menu-toggle').removeClass('menu-active');
    } else {
      $('#sidebar').addClass('active');
      $('.overlay').addClass('active');
      $('footer').hide();
      $('.content').addClass('visibility-hidden');
      $('.collapse.in').toggleClass('in');
      $('a[aria-expanded=true]').attr('aria-expanded', 'false');
      $('.menu-toggle').addClass('menu-active');
    }
    event.preventDefault();
    return false;
  });

  $('.to-top-link').on('click', function(event) {
    $("html, body").animate({ scrollTop: 0 }, 500);
    return false;
  });
};

registerInitializer(initializeNavigationSlider);
