import {registerInitializer} from "../shared/utils";

import flexslider from 'flexslider'
import 'flexslider/flexslider.css';

export const initializeFlexslider = () => {
  if($(".flexslider").length) {

    var el = document.querySelector(".current-index");
    $('#flexslider').removeData("flexslider");

    $('.flexslider').flexslider({
      animation: "slide",
      slideshow: false,
      startAt: parseInt(el.dataset.current),
      controlNav: false,
      smoothHeight: false,
      start: function(slider) {
        $('.total-slides').text(slider.count);
      },
      after: function(slider) {
        $('.current-slide').text(slider.currentSlide + 1);
      }
    });
  }
};

registerInitializer(initializeFlexslider);
